/* eslint-env browser */
import React, { Component } from 'react';
import { Link } from 'gatsby';
import PropTypes from 'prop-types';
import { imageBase } from '../constants';

import * as S from './sub-components/styled-components/imageSquareCardStyles';

class ImageSquareCard extends Component {
  disableRightClick() {
    // e.preventDefault();
  }

  state = { width: 350 };

  sendAnalytics(url) {
    if (!window.gtag) return false;

    typeof window !== 'undefined' &&
      window.gtag('event', 'click', {
        event_category: 'Navigate',
        event_label: `Click on grid image ${url}`,
        image_name: url,
      });
  }

  componentDidMount() {
    const width = window.innerWidth;
    if (width) {
      this.setState({ width });
    }
  }

  render() {
    const {
      work: {
        available,
        gridImageSmallSrc,
        gridImageSmall2xSrc,
        gridImageSrc,
        gridImage2xSrc,
        title,
        url,
        alt,
        width,
        height,
        smallWork,
        price,
      },
      index,
    } = this.props;

    const imageSource =
      this.state.width > 350 ? gridImageSrc : gridImageSmallSrc;
    const image2xSource =
      this.state.width > 350 ? gridImage2xSrc : gridImageSmall2xSrc;

    const imageSrc = `${imageBase + imageSource}`;
    const retinaImageSrc = image2xSource
      ? `${imageBase + image2xSource}`
      : imageSrc;
    const srcSet = image2xSource
      ? `${imageSrc} 1x, ${retinaImageSrc} 2x`
      : null;

    return (
      <Link to={`/works/${url}`} onClick={() => this.sendAnalytics(url)}>
        <S.CardWrapper
          onContextMenu={(e) => this.disableRightClick(e)}
          index={index}
        >
          <S.ImageBox>
            <S.Image src={imageSrc} srcSet={srcSet} alt={alt} />
            <S.ImageShadow />
          </S.ImageBox>
          <S.Title>
            {title}
            {!available && (<svg xmlns="http://www.w3.org/2000/svg" version="1.1" width="12" height="12" style={{'margin-left': '5px', 'margin-bottom': '-1px'}}>
              <circle r="6" cx="6" cy="6" style={{fill:'red'}}/>
            </svg>)}
          </S.Title>
          <S.Title>{`${height}" x ${width}"`}</S.Title>
          {smallWork && <S.Title>{`$${price}`}</S.Title>}
        </S.CardWrapper>
      </Link>
    );
  }
}

ImageSquareCard.propTypes = {
  work: PropTypes.object,
  index: PropTypes.number,
};

export default React.memo(ImageSquareCard);
