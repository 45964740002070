import React from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';
import { WorksGridBox } from '../worksGrid';
import { mediumBreakpointMinus } from '../../constants';

const HomeTextContentBox = styled.main`
  box-sizing: border-box;
  width: 80vw;
  display: flex;
  justify-content: center;
  flex-direction: column;
  margin: 15px auto 0 auto;
  max-width: 1125px;
  @media only screen and (max-width: ${mediumBreakpointMinus}) {
    width: 345px;
  }
`;

const HomeTextHeader = styled.h2`
  font-size: 22px;
  line-height: 27px;
  font-weight: 500;
  text-align: center;
  margin: 15px 0 0 0;
  @media only screen and (max-width: ${mediumBreakpointMinus}) {
    line-height: 28px;
  }
`;

const HomeTextBody = styled.p`
  font-size: 18px;
  line-height: 32px;
  font-weight: 400;
  text-align: left;
  margin: 15px 0 0 0;
  @media only screen and (max-width: ${mediumBreakpointMinus}) {
    line-height: 28px;
  }
`;

const LineBreak = styled.span`
  display: none;
  @media only screen and (max-width: ${mediumBreakpointMinus}) {
    display: inline;
  }
`;

const SpanWithLinks = styled.span`
  a {
    text-decoration: underline;
  }
`;

const HomeText = ({ smallWorks = false }) => {
  const renderBodyText = () => {
    if (!smallWorks) {
      return (
        <SpanWithLinks>
          Best known for bold use of color, Jennifer infuses her abstract
          artworks with movement, positive energy, and feelings of happiness and
          joy. Choose from the available <Link to={'/#works'}>art gallery</Link>
          , <Link to={'/small-works'}>small art gallery</Link>, or{' '}
          <Link to={'/commissions'}>commission</Link> a custom piece.
        </SpanWithLinks>
      );
    }
    return (
      <span>
        Small works are the perfect way to light up your small spaces, great for
        gift giving, a smart and easy way to start collecting art. Here you’ll
        find smaller abstracts, waves, and rainbows by Jennifer Moné Hill.
      </span>
    );
  };

  const headlineText = !smallWorks
    ? 'Colorful Abstract Paintings by Connecticut artist'
    : 'Colorful Abstract Small Works by Connecticut artist';

  return (
    <WorksGridBox>
      <HomeTextContentBox>
        <HomeTextHeader>
          {headlineText}&nbsp;
          <LineBreak>
            <br />
          </LineBreak>
          Jennifer Moné Hill
        </HomeTextHeader>
        <HomeTextBody>{renderBodyText()}</HomeTextBody>
      </HomeTextContentBox>
    </WorksGridBox>
  );
};

export default HomeText;
