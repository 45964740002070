/* eslint-env browser */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { isMobile } from 'react-device-detect';
import InfiniteScroll from 'react-infinite-scroll-component';
import ImageSquareCard from '../imageSquareCard';

const renderWorks = (works, numberOfBoxes) => {
  const slicedWorks = works.slice(0, numberOfBoxes);

  return slicedWorks.map((work, idx) => {
    return (
      <ImageSquareCard
        work={work}
        key={`image-square-card-${idx}`}
        index={idx}
      />
    );
  });
};
export const WorksBoxList = ({ works, length, smallWorks }) => {
  let initalAmount = isMobile ? 1 : 3;
  if (smallWorks) {
    initalAmount = isMobile ? 3 : 12;
  }
  const [numberOfBoxes, setNumberOfBoxes] = useState(initalAmount);

  const fetchData = () => {
    const incrementAmount = isMobile ? 2 : 3;
    let newNumber = numberOfBoxes + incrementAmount;
    if (newNumber > length) {
      newNumber = length;
    }
    setNumberOfBoxes(newNumber);
  };

  return (
    <InfiniteScroll
      dataLength={numberOfBoxes} //This is important field to render the next data
      next={fetchData}
      hasMore={true}
      scrollThreshold={isMobile ? '200px' : '400px'}
    >
      {renderWorks(works, numberOfBoxes)}
    </InfiniteScroll>
  );
};

WorksBoxList.propTypes = {
  works: PropTypes.array,
  length: PropTypes.number,
};

export default React.memo(WorksBoxList);
