/* eslint-env browser */
import React, { Component } from 'react';
import { Link } from 'gatsby';
import { isMobile } from 'react-device-detect';
import styled from 'styled-components';
import _get from 'lodash.get';
import _sortBy from 'lodash.sortby';
import _map from 'lodash.map';
// import _head from 'lodash.head';
// import _filter from 'lodash.filter';
// import shortid from 'shortid';
import Slider from 'react-slick';
// import ReactPlayer from 'react-player';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import './sub-components/styled-components/homeCarousel.scss';

import { imageBase, sliderSettingsBase } from '../constants';
import { buildSrcSet } from '../util';
import { graphql, StaticQuery } from 'gatsby';

// const CarouselImageWrapper = styled.div`
//   height: 100%;
//   width: 100%;
//   display: flex !important;
//   justify-content: center;
//   align-items: center;
//   position: relative;
// `;

const CarouselImage = styled.img`
  width: 100vw;
  height: 48.12vw;
`;

class HomeCarouselClass extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ...sliderSettingsBase,
      speed: 1000,
      autoplaySpeed: 2500,
      pauseOnFocus: true,
      autoplay: true,
      lazyLoad: true,
      dots: !isMobile,
    };
    this.slider = React.createRef();
    this.fixCarousel = this.fixCarousel.bind(this);
  }

  componentDidMount() {
    window.addEventListener('resize', this.fixCarousel);
    window.addEventListener('orientationchange', this.fixCarousel);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.fixCarousel);
    window.removeEventListener('orientationchange', this.fixCarousel);
  }

  fixCarousel() {
    this.slider.current.innerSlider.onWindowResized();
  }

  disableRightClick(e) {
    e.preventDefault();
  }

  // onVideoStart() {
  //   const currentSlider = _get(this, ['slider', 'current']);
  //   if (!currentSlider) return;
  //   setTimeout(() => currentSlider.slickPlay(), 48000); // eslint-disable-line
  // }

  render() {
    const { data } = this.props;
    const allMedia = _get(data, 'allHomeImagesCsv.edges');
    let images = _map(allMedia, item => _get(item, 'node'));
    images = _sortBy(images, ['sort']);
    // const noVideoImages = _filter(images, image => image.image_1 !== '');

    // const firstImage = _head(noVideoImages);
    // if (isMobile) {
    //   images = noVideoImages;
    // }

    const imageElements = _map(images, image => {
      // if (image.vimeoId) {
      //   return (
      //     <CarouselImageWrapper key={shortid.generate()}>
      //       <img
      //         src={imageBase + _get(firstImage, 'image_1')}
      //         srcSet={buildSrcSet(firstImage)}
      //         alt={_get(firstImage, 'title')}
      //       />
      //       <ReactPlayer
      //         className="react-player"
      //         url={`https://vimeo.com/${image.vimeoId}`}
      //         config={{
      //           vimeo: {
      //             playerOptions: {
      //               width: 1920,
      //               autoplay: true,
      //               muted: true,
      //               background: true,
      //               responsive: false,
      //               loop: true,
      //             },
      //           },
      //         }}
      //         width="100%"
      //         height="100%"
      //         // autoPlay={true}
      //         loop={true}
      //         // onEnded={() => this.onVideoEnd()}
      //         onStart={() => this.onVideoStart()}
      //       />
      //     </CarouselImageWrapper>
      //   );
      // }
      return (
        <Link to={`/${_get(image, 'link')}`} key={_get(image, 'title')}>
          <CarouselImage
            src={imageBase + _get(image, 'image_1')}
            srcSet={buildSrcSet(image)}
            alt={_get(image, 'title')}
          />
        </Link>
      );
    });

    return (
      <Slider {...this.state} ref={this.slider}>
        {imageElements}
      </Slider>
    );
  }
}

const HomeCarousel = () => (
  <StaticQuery
    query={graphql`
      query allHomeImagesCsv {
        allHomeImagesCsv {
          edges {
            node {
              title
              link
              sort
              vimeoId
              image_1
              image_2
              image_3
              image_4
              image_5
              image_6
              image_7
              image_8
              image_9
            }
          }
        }
      }
    `}
    render={data => {
      return <HomeCarouselClass data={data} />;
    }}
  />
);

export default HomeCarousel;
