import styled from 'styled-components';
import {
  transitionTime,
  photoDimension,
  smallPhotoDimension as smallPhoto,
  iPhone5Breakpoint,
  mediumBreakpointMinus,
} from '../../../constants';

export const CardWrapper = styled.div`
  width: ${photoDimension};
  height: 435px;
  margin: 30px 15px 0 15px;
  @media only screen and (max-width: ${mediumBreakpointMinus}) {
    margin-top: ${props => (props.index !== 0 ? '30px' : 0)};
  }
  background: white;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  a {
    text-decoration: none;
  }
  &:hover {
    img,
    div {
      transform: translate3d(-1px, -1px, 0);
      opacity: 1;
    }
  }
  @media only screen and (max-width: ${iPhone5Breakpoint}) {
    width: ${smallPhoto};
    height: 335px;
    margin: 15px 10px 0 10px;
  }
`;

export const ImageBox = styled.div`
  width: ${photoDimension};
  height: ${photoDimension};
  display: block;
  transition: all ${transitionTime};
  position: relative;
  top: 0;
  left: 0;
  @media only screen and (max-width: ${iPhone5Breakpoint}) {
    width: ${smallPhoto};
  }
`;

export const Image = styled.img`
  width: ${photoDimension};
  height: ${photoDimension};
  transition: all ${transitionTime};
  position: absolute;
  top: 0;
  left: 0;
  z-index: 3;
  @media only screen and (max-width: ${iPhone5Breakpoint}) {
    width: ${smallPhoto};
    height: ${smallPhoto};
  }
`;

export const ImagePlaceholder = styled.img`
  width: ${photoDimension};
  height: ${photoDimension};
  position: absolute;
  background: white;
  top: 0;
  left: 0;
  z-index: 3;
  @media only screen and (max-width: ${iPhone5Breakpoint}) {
    width: ${smallPhoto};
    height: ${smallPhoto};
  }
`;

export const ImageShadow = styled.div`
  width: ${photoDimension};
  height: ${photoDimension};
  position: absolute;
  background: white;
  top: 0;
  left: 0;
  transition: all ${transitionTime};
  box-shadow: 3px 3px 11px 0px rgba(0, 0, 0, 0.25);
  opacity: 0;
  @media only screen and (max-width: ${iPhone5Breakpoint}) {
    width: ${smallPhoto};
    height: ${smallPhoto};
  }
`;

export const Title = styled.p`
  width: 100%;
  text-align: center;
  color: black;
  font-size: 14px;
  display: block;
  margin: 3px;
  transition: all ${transitionTime};
  &:hover {
    opacity: 0.85;
  }
`;
