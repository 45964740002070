/* eslint-env browser */
import React, { Component } from 'react';
import styled from 'styled-components';
import WorksGrid from './worksGrid';
import ElevatorButton from './sub-components/elevatorButton';
import HomeCarousel from './homeCarousel';
import HomeText from './sub-components/homeText';

const HomeBox = styled.div`
  width: 100%;
  height: 100%;
  .slick-list {
    width: 100vw;
    height: 48.12vw;
    .slick-track {
      height: 48.12vw;
    }
  }
`;

class Home extends Component {
  constructor(props) {
    super(props);
    this.reload = this.reload.bind(this);
  }

  disableRightClick(e) {
    e.preventDefault();
    return;
  }

  componentDidMount() {
    window.addEventListener('orientationchange', this.reload);
  }

  componentWillUnmount() {
    window.removeEventListener('orientationchange', this.reload);
  }

  reload() {
    this.forceUpdate();
  }

  render() {
    const showSmallWorks = this.props.showSmallWorks;
    return (
      <HomeBox>
        <ElevatorButton showThreshold={700} />
        {!showSmallWorks && <HomeCarousel />}
        <HomeText smallWorks={showSmallWorks}/>
        <WorksGrid smallWorks={showSmallWorks} />
      </HomeBox>
    );
  }
}

export default Home;
