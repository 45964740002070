import React, { Component } from 'react';
import ScrollToTop from 'react-scroll-up';
import styled from 'styled-components';
import PropTypes from 'prop-types';

const TopButton = styled.div`
  width: 50px;
  height: 75px;
  border: 1px solid lightgray;
  opacity: 0.75;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: rgba(255, 255, 255, 0.75);
  WebkitTransform: translate3d(0, 0, 0);
  span {
    width: 100%;
    height: 100%;
    padding: 8px 0;
    display: block;
    text-align: center;
    line-height: 16px;
    background: rgba(255, 255, 255, 0.75);
  }
`;

class ElevatorButton extends Component {
  render() {
    const { showThreshold } = this.props;
    return (
      <ScrollToTop
        showUnder={showThreshold}
        duration={1000}
        style={{ WebkitTransform: 'translate3d(0,0,0)', zIndex: 3000 }}
      >
        <TopButton>
          <span>
            ^<br />^<br />
            TO
            <br />
            TOP
          </span>
        </TopButton>
      </ScrollToTop>
    );
  }
}

ElevatorButton.propTypes = {
  showThreshold: PropTypes.number,
};

export default ElevatorButton;
