/* eslint-env browser */
import React from 'react';
import styled from 'styled-components';
import WorksBoxList from './sub-components/worksBoxList';
import _get from 'lodash.get';
import _filter from 'lodash.filter';
import _sortBy from 'lodash.sortby';

import {
  iPhone5Breakpoint,
  mediumBreakpointMinus,
  mediumBreakpoint,
  largeBreakpointMinus,
  largeBreakpoint,
} from '../constants';
import { graphql, StaticQuery } from 'gatsby';

export const WorksGridBox = styled.div`
  .infinite-scroll-component {
    margin: 0 auto;
    background: white;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    @media only screen and (max-width: ${iPhone5Breakpoint}) {
      margin: 15px auto 0 auto;
    }
    @media only screen and (max-width: ${mediumBreakpointMinus}) {
      flex-direction: column;
      flex-wrap: unset;
      width: 100%;
      align-items: center;
    }
    @media only screen and (min-width: ${mediumBreakpoint}) and (max-width: ${largeBreakpointMinus}) {
      width: ${mediumBreakpoint};
    }
    @media only screen and (min-width: ${largeBreakpoint}) {
      width: ${largeBreakpoint};
    }
  }
`;

const WorksGrid = ({ smallWorks = false }) => (
  <StaticQuery
    query={graphql`
      query listWorksAppsync {
        jmonehillart {
          listWorks(
            filter: { visible: { eq: true } }
            # sort: { direction: asc, field: sort }
            limit: 500
          ) {
            items {
              available
              gridImagelowres
              gridImageSmallSrc
              gridImageSmall2xSrc
              gridImageSrc
              gridImage2xSrc
              alt
              title
              sort
              url
              width
              height
              smallWork
              price
              visible
            }
          }
        }
      }
    `}
    render={(data) => {
      let works = _get(data, 'jmonehillart.listWorks.items', []);
      works = _sortBy(works, ['sort']);

      works = _filter(works, (work) => {
        if (work.smallWork === null) {
          work.smallWork = false;
        }
        if (work.smallWork === smallWorks) {
          return work;
        }
      });

      return (
        <WorksGridBox id="works">
          <WorksBoxList
            works={works}
            length={works.length}
            smallWorks={smallWorks}
          />
        </WorksGridBox>
      );
    }}
  />
);

export default WorksGrid;
